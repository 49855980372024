
import React, { useEffect} from "react";

export default function ContatoInfo(){


    return (
      <div>
          <div className="m-sm-30">
              <div className="mb-sm-30">
          <h3>Suporte Técnico</h3>
          <p>Em caso de problemas/erros técnicos, abra um chamado em : <a target={"_blank"} href={"https://sinapse.ufpi.br"}>sinapse.ufpi.br</a></p>
          <p>Ou envie um Email: alunosconectados.suporte@ufpi.edu.br</p>

      </div></div></div>
    );

}