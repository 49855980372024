import http from "../../../http-common"

class TermoService {

    getTermo()  {
        return http.get("/termo/get");
    }

    salvaTermo(termo){
        return http.post("/termo/save", termo);
    }

    getOpcoesRecebimento () {
        return http.get("/termo/getOpcoesRecebimento");
    }

    valida(chave){
        return http.get("/termo/valida?chave="+chave);
    }

    recursoTermo(termo){
        return http.post("/termo/recurso", termo);
    }

    listTermoPag(termo,page,size){
        return http.post("/termo/listTermos?page="+page+"&size="+size, termo);
    }
    listTermo( termoConfigSelecionado){

        return http.get("/termo/listTermos?idConfigTermo="+ termoConfigSelecionado.id);
    }
    addChip(idTermo, chip){
        return http.post("/termo/addChip?idTermo="+idTermo+"&chip="+chip);
    }
    getEtiquetas(listIdTermo){
        return http.post("/termo/getEtiquetas",listIdTermo,{responseType: 'blob',
            headers: {
                'Accept': 'application/pdf'}});
    }
    getRelatorio(listIdConfig){
        return http.get("/relatorios/quantidadeInscritos?values="+listIdConfig.join(","),
            {responseType:"blob",
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}});
    }
    getRelatorioInscritos(listIdConfig){
        return http.get("/relatorios/inscritos?values="+listIdConfig.join(","),
            {responseType:"blob",
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}});
    }
}

export default new TermoService();