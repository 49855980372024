import React, { useEffect, useState} from "react";
import {Box, Checkbox, FormControlLabel, Grid, InputLabel, Select, TextField} from "@material-ui/core";

import InputMask from "react-input-mask";
import {SimpleCard} from "../../../matx";
import TermoService from "../../services/AlunosConectadosService/TermoService";

class ValidaChave extends React.Component{

    constructor(props) {
        super(props);
        this.state = {chave: this.props.match.params.chave,
                        message:""}
    }

    valida = () => {
      TermoService.valida(this.state.chave).then(result =>{
            console.log(result);
            if(result.data){
                this.setState({...this.state, message:<div> <p>Termo validado com sucesso.</p> </div>});
            }else{
                this.setState({...this.state, message:<div> <p>Ocorreu algum problema. O termo ja foi validado ou a chave é inválida.</p> </div>});

            }
        }).catch(ex => {
          this.setState({...this.state, message:<div> <p>{ex.response.data.message}</p></div>});

        })

       // this.state.chave = null;
    }
    componentDidMount() {
        this.valida();
    }

    render() {
       return <div align='center'>
           {this.state.message}
       </div>
   }
}

export default ValidaChave;