import { MatxLoadable } from "matx";
import ValidaChave from "./ValidaChave";

const TermoForm = MatxLoadable({
    loader: () => import("./TermoForm")
});
const ListTermoTable = MatxLoadable({
    loader: () => import("./list/ListTermoTable")
});
const Relatorios = MatxLoadable({
    loader: () => import("./list/Relatorios")
});

function verifyAdmin() {
    if(!localStorage.getItem("jwt_token")){
        return false;
    }
    const jwtDecode = require('jwt-decode');
    const decoded = jwtDecode(localStorage.getItem("jwt_token"));
    if (!decoded.authorities.includes('ADMIN')) {
        return false;
    }
    return true;
}
const termoRoutes = [
    {
        path: "/termo/formulario",
        component: TermoForm
    },{
        path: "/termo/valida/:chave",
        component: ValidaChave
    },
];

if(verifyAdmin()){
    termoRoutes.push({
    path: "/termo/list",
    component: ListTermoTable
        },{
        path: "/termo/relatorios",
        component: Relatorios
    })
}

export default termoRoutes;
