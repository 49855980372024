import React from "react";
import { Redirect } from "react-router-dom";

import sessionRoutes from "./views/sessions/SessionRoutes";
import termoRoutes from "./views/termo/TermoRoutes";
import ValidaChave from "./views/termo/ValidaChave";
import ContatoInfo from "./views/ContatoInfo";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/termo/formulario" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const contatoRoute = [
  {
    path: "/suporte",
    component: ContatoInfo
  }
];

const routes = [
/*    ...dashboardRoutes,
    ...utilitiesRoutes,
    ...sessionRoutes,
    ...materialRoutes,
    ...dragAndDropRoute,
    ...formsRoutes,
    ...mapRoutes,*/
  ...termoRoutes,
  ...contatoRoute,
  ...sessionRoutes,
  ...redirectRoute,
  ...errorRoute
];

export default routes;
