import axios from "axios";
import localStorageService from "./localStorageService";
import {BASE_URL_AUTH} from "../../properties";

class JwtAuthService {

    getRequestOptions = (username,password) => {
        const details = {
            'username':username,
            'password':password,
            'grant_type':'password'
        };

        const clientLogin = "Basic "+ btoa('sti:n%vSuTiz8ZB6%nW16@boc%KZQ!rAJhcyKbMpoIxp%GsqnlqW5y');

        const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '='+ encodeURIComponent(details[key]))
            .join('&');

        return {
            method: 'POST',
            mode:'cors',
            headers: {
                'Accept':'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization':clientLogin,
                'Sistema':'alunosconectados',
            },
            body: formBody,
            withCredentials: true
        };
    }

  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  loginWithEmailAndPassword = (username, password) => {
;
    const requestOptions = this.getRequestOptions(username,password);

    return fetch(BASE_URL_AUTH +'/oauth/token',requestOptions)
        .then(response => response.json())
        .then(data => {
          const jwt = data.access_token;
          this.setSession(jwt);

          const jwtDecode = require('jwt-decode');
          const decoded = jwtDecode(jwt);
          let role = 'GUEST';
          if(decoded.authorities.includes('ADMIN')){
            role = 'ADMIN';
          }
          this.user = {role:role,displayName: decoded.user_name };
          this.setUser(this.user);
          return this.user;
          });
  };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = () => {
    const requestOptions ={
      method: 'POST',
      headers: {
        'Accept':'application/json'
      },
      body: localStorage.getItem(
      "jwt_token"),
    };
    return fetch(BASE_URL_AUTH+'/validateToken',requestOptions)
        .then(response => response.json())
        .then(data => {

            console.log(data);
          let valido = data;

          if(valido.error){
              this.setSession(null);
              this.setUser(null);
              return false;
          }

          if(!valido){
            // Token is valid
            this.setSession(null);
            this.setUser(null);
            return data;
          }
          return valido;

        }).catch(err => {

            return false;
        });

  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  // Set token to all http request header, so you don't need to attach everytime
  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }

  };

  // Save user to localstorage
  setUser = (user) => {    
    localStorageService.setItem("auth_user", user);
  }
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
      localStorage.removeItem("jwt_token");
  }
}

export default new JwtAuthService();
